import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      redirect: () => ({ name: 'Campaigns' }),
    },
    {
      path: '/campaigns',
      name: 'Campaigns',
      component: () => import('@/views/CampaignsView.vue'),
    },
    {
      path: '/campaign/:campaignId',
      name: 'EditCampaign',
      component: () => import('@/views/EditCampaignView.vue'),
      props: (args) => {
        return {
          id: args.params['campaignId'],
        };
      },
    },
    {
      path: '/resources',
      name: 'Resources',
      component: () => import('@/views/ResourcesView.vue'),
    },
    {
      path: '/resource/:resourceId?',
      name: 'EditResource',
      component: () => import('@/views/EditResourceView.vue'),
      props: (args) => {
        return {
          id: args.params['resourceId'] != null ? Number(args.params['resourceId']) : undefined,
        };
      },
    },
    {
      path: '/wrapper/:wrapperId?',
      name: 'EditWrapper',
      component: () => import('@/views/EditWrapperView.vue'),
      props: (args) => {
        return {
          id: args.params['wrapperId'] != null ? Number(args.params['wrapperId']) : undefined,
        };
      },
    },
    {
      path: '/sites',
      name: 'Sites',
      component: () => import('@/views/SitesView.vue'),
    },
    {
      path: '/site/:siteId',
      name: 'EditSite',
      component: () => import('@/views/EditSiteView.vue'),
      props: (args) => {
        return {
          siteId: args.params['siteId'],
        };
      },
    },
    {
      path: '/forbidden',
      name: 'Forbidden',
      component: () => import('@/views/ForbiddenView.vue'),
    },
  ],
});

export default router;
